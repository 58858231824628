import { UseMutationResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  Intake,
  OrganisationForm,
  ArrayResponseType,
  CreateOrganisationForm,
  IntakeFormQuestion,
  CreateIntake,
  CreateIntakeFormQuestion,
  IntakePublicFormQuestions,
  IntakeCSVData,
  ReferenceQuestionType,
  IntakeSubmission,
} from 'data/types';
import { intakesKeys } from 'data/utils/hookKeys';
import queryString from 'qs';
import { IntakesTableAccessor, SortDirection } from 'data/enums';
import { defaultIntakesSort } from 'app/components/app/therapist/intakes/constants';

import * as api from '../actions-query';

const defaultIntakesParams = {
  sort: {
    [defaultIntakesSort.sort]: SortDirection.asc,
  },
  limit: 12,
  offset: 0,
};

interface IntakesParams {
  sort: {
    [key in IntakesTableAccessor]?: SortDirection;
  };
  limit?: number;
  offset?: number;
  filters?: {
    search?: string | null;
    statuses?: string;
    createdAtFrom?: string;
    createdAtTo?: string;
  };
}

export const useIntakes = (organisationId: string, params?: IntakesParams) => {
  const query = queryString.stringify(params);
  return useQuery<ArrayResponseType<Intake>, AxiosError>(
    intakesKeys.all(query),
    () => api.fetchIntakes(organisationId, query),
    {
      enabled: !!organisationId,
    },
  );
};

export const useOrganisationForm = (organisationId: string) =>
  useQuery<OrganisationForm, AxiosError>(
    intakesKeys.organisationForm(organisationId),
    () => api.fetchOrganisationForm(organisationId),
    { enabled: !!organisationId },
  );

export const useOrganisationFormQuestions = (organisationId: string, formId: string) =>
  useQuery<ArrayResponseType<IntakeFormQuestion>, AxiosError>(
    intakesKeys.organisationFormQuestions(organisationId, formId),
    () => api.fetchOrgsanisationFormQuestions(formId),
    { enabled: !!formId },
  );

export const usePublicFormQuestions = (token: string) =>
  useQuery<IntakePublicFormQuestions, AxiosError>(
    intakesKeys.publicFormQuestions(token),
    () => api.fetchPublicFormQuestions(token),
    { enabled: !!token },
  );

export const useCreateOrganisationForm = (): UseMutationResult<
  OrganisationForm,
  AxiosError,
  { organisationId: string; body: CreateOrganisationForm },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(({ organisationId, body }) => api.createOrganisationForm(organisationId, body), {
    onSuccess: (_, { organisationId }) => {
      queryClient.invalidateQueries(intakesKeys.organisationForm(organisationId));
    },
  });
};

export const useCreateOrganisationFormQuestions = (
  organisationId: string,
): UseMutationResult<
  ArrayResponseType<IntakeFormQuestion>,
  AxiosError,
  { formId: string; body: { questions: CreateIntakeFormQuestion[] } },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(({ formId, body }) => api.createOrganisationFormQuestions(formId, body), {
    onSuccess: (_, { formId }) => {
      queryClient.invalidateQueries(intakesKeys.organisationFormQuestions(organisationId, formId));
    },
  });
};

export const useDeleteOrganisationFormQuestions = (
  organisationId: string,
): UseMutationResult<void, AxiosError, { formId: string; body: { ids: string[] } }, unknown> => {
  const queryClient = useQueryClient();

  return useMutation(({ formId, body }) => api.deleteOrganisationFormQuestions(formId, body), {
    onSuccess: (_, { formId }) => {
      queryClient.invalidateQueries(intakesKeys.organisationFormQuestions(organisationId, formId));
    },
  });
};

export const useUpdateOrganisationFormQuestions = (
  organisationId: string,
): UseMutationResult<
  ArrayResponseType<IntakeFormQuestion>,
  AxiosError,
  { formId: string; body: { questions: CreateIntakeFormQuestion[] } },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(({ formId, body }) => api.updateOrganisationFormQuestions(formId, body), {
    onSuccess: (_, { formId }) => {
      queryClient.invalidateQueries(intakesKeys.organisationFormQuestions(organisationId, formId));
    },
  });
};

export const useAnswerIntakeFormPreview = (
  organisationId: string,
): UseMutationResult<Intake, AxiosError, CreateIntake, unknown> => {
  const queryClient = useQueryClient();

  return useMutation((body) => api.answerIntakeFormPreview(organisationId, body), {
    onSuccess: () => {
      queryClient.invalidateQueries(intakesKeys.all());
    },
  });
};

export const useAnswerPublicIntakeForm = (
  token: string,
): UseMutationResult<
  Omit<Intake, 'orderIndex' | 'status' | 'referredTo' | 'deletedAt' | 'record' | 'teams'>,
  AxiosError,
  CreateIntake,
  unknown
> => useMutation((body) => api.answerPublicIntakeForm(token, body));

export const useIntake = (intakeId: string) =>
  useQuery<Intake, AxiosError>(intakesKeys.details(intakeId), () => api.fetchIntake(intakeId), {
    enabled: !!intakeId,
  });

export const useEditIntake = (): UseMutationResult<
  Intake,
  AxiosError,
  { intakeId: string; body: unknown; onSuccess?: () => void },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(({ intakeId, body }) => api.editIntake(intakeId, body), {
    onMutate: async ({ intakeId, body }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: intakesKeys.details(intakeId) });

      // Snapshot the previous value
      const previousIntake = queryClient.getQueryData(intakesKeys.details(intakeId));

      // Optimistically update to the new value
      queryClient.setQueryData(intakesKeys.details(intakeId), (old) => {
        let transformedBody = body;
        if (body?.assignees?.userIds?.length) {
          transformedBody = {
            ...body,
            teams: [...old?.teams, { teamUsers: [{ user: { id: body?.assignees?.userIds[0] } }] }],
          };
        }
        return { ...old, ...transformedBody };
      });

      // Return a context object with the snapshotted value
      return { previousIntake };
    },
    onSuccess: (_, { onSuccess }) => onSuccess && onSuccess(),
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, { intakeId }, context) => {
      queryClient.setQueryData(intakesKeys.details(intakeId), context?.previousIntake);
    },
    // Always refetch after error or success:
    onSettled: (data, err, { intakeId }) => {
      queryClient.invalidateQueries({ queryKey: intakesKeys.details(intakeId) });
      queryClient.invalidateQueries({ queryKey: intakesKeys.timeline(intakeId) });
    },
  });
};

export const useDeleteIntake = (): UseMutationResult<void, AxiosError, { intakeId: string }, unknown> =>
  useMutation(({ intakeId }) => api.deleteIntake(intakeId));

export const useEditOrganisationForm = (
  orgId: string,
): UseMutationResult<OrganisationForm, AxiosError, { formId: string; body: any }, unknown> => {
  const queryClient = useQueryClient();
  return useMutation(({ formId, body }) => api.editOrganisationForm(formId, body), {
    onSuccess: () => queryClient.invalidateQueries(intakesKeys.organisationForm(orgId)),
  });
};

export const useImportIntakeInBulk = (organisationId: string) => {
  const query = queryString.stringify(defaultIntakesParams);
  const queryClient = useQueryClient();
  return useMutation<ArrayResponseType<Intake>, AxiosError, { items: IntakeCSVData[] }, unknown>(
    (body) => api.importIntakeInBulk(organisationId, body),
    { onSuccess: () => queryClient.invalidateQueries(intakesKeys.all(query)) },
  );
};

export const useReferenceQuestionTypes = () =>
  useQuery<ArrayResponseType<ReferenceQuestionType>, AxiosError>(intakesKeys.referenceTypes(), () =>
    api.fetchReferenceQuestionTypes(),
  );
