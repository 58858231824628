import { services, staticValues } from '../constants';

const { upgradeInsecureRequests, self, none, data, blob, https, unsafeInline, blockAllMixedContent } = staticValues;
const {
  apiOrigin,
  google,
  intercom,
  facebook,
  clarity,
  linkedin,
  videoMeeting,
  officePreview,
  devCycle,
  realTime,
  sentry,
  tiny,
  pdfjs,
} = services;

export const useContentSecurityPolicy = () => {
  const content = `          
    ${upgradeInsecureRequests};
    ${blockAllMixedContent};
    style-src ${self} ${unsafeInline} ${tiny.tinymceCloudSubdomain} ${tiny.tinymceSubdomain} ${google.apis};
    img-src ${self} ${blob} ${data} ${https} ${apiOrigin} ${tiny.tinymceCloudSubdomain} ${tiny.tinymceSubdomain} ${google.apis};
    script-src ${self} ${unsafeInline} ${google.analytics} ${google.tagManagerSubdomain} ${google.doubleClickSubdomain} ${google.subdomain} ${google.staticSubdomain} ${facebook.netSubdomain} ${facebook.comSubdomain} ${intercom.subdomain} ${intercom.cdn} ${clarity.subdomain} 
${linkedin.subdomain} ${videoMeeting.domain} ${tiny.tinymceCloudSubdomain} ${tiny.tinymceSubdomain} ${pdfjs.dist} ${pdfjs.unpkg};
    connect-src ${self} ${apiOrigin} ${google.analytics} ${google.doubleClickSubdomain} ${facebook.netSubdomain} ${facebook.comSubdomain} ${intercom.subdomain} ${intercom.cdn} ${clarity.subdomain} ${sentry.ingestSubdomain} ${devCycle.subdomain} ${realTime.domain} ${tiny.tinymceCloudSubdomain} ${tiny.tinymceSubdomain} ${google.apis} ${linkedin.adsSubdomain} ${intercom.nexus};
    child-src ${self} ${google.subdomain} ${google.doubleClick} ${officePreview.domain} ${videoMeeting.domain} ${facebook.comSubdomain};
    form-action ${self} ${facebook.comSubdomain};
    base-uri ${self};
    object-src ${none};
    font-src ${self} ${tiny.tinymceCloudSubdomain} ${tiny.tinymceSubdomain} ${google.staticSubdomain};
    worker-src ${blob};
  `;

  return { content };
};
